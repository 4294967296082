<template>
    <div class="container">
        <main>
            <!-- 因JS无法关闭浏览器，不再需要该按钮 -->
            <!-- <div class="exit">
                <button @click="$router.go(-1)">
                    <i class="icon-leftarrow"></i>退出問診
                </button>
            </div> -->
            <doctor-chat
                class="chat"
                :roomId="roomId"
                :doctorAvatar="doctorAvatar"
                :isMsg="true"
            ></doctor-chat>
            <doctor-attachment
                :caseId="caseId"
                :roomId="roomId"
                type="text"
            ></doctor-attachment>
        </main>
    </div>
</template>

<script>
import { chatroomsDetail, chatroomslogin } from "@/api/chat.js";
import DoctorChat from "./doctorChatroomComponents/doctorChat.vue";
import { mapState } from "vuex";
import DoctorAttachment from "./doctorChatroomComponents/doctorAttachment.vue";
import clinicMixin from "./chatroomMixins/clinicMixin.js";
export default {
    components: {
        DoctorChat,
        DoctorAttachment,
    },
    mixins: [clinicMixin],
    data: function () {
        return {
            type: "text",
        };
    },
    computed: {
        ...mapState({
            userSig: (state) => state.doctor.userSig,
            userID: (state) => state.doctor.userId,
        }),
    },
    methods: {
        chatroomsDetail,
        chatroomslogin,
    },
    created() {
        this.getChatroomDetail(this.$route.query.room).then((res) => {
            let roomData = res.data;
            this.setChatroomData(roomData);
            this.login({
                userId: roomData.doctorUserId,
                userSig: roomData.doctorUserSig,
                token: roomData.doctorToken,
            });
            // TODO: 后续可能还要加入诊所职员的id
            this.tim.receivedAccount = [roomData.doctorUserId];
            if (!this.isChatroomClosed) {
                this.prepareSDK();
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 100vw;
    min-width: 1024px;
    height: 100vh;
    background-color: $green-200;
    padding: 32px;
    @include flexr-center-stretch;
    main {
        flex-grow: 1;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 300px;
        grid-template-areas: "chat info";
        div.exit button {
            color: white;
            font-size: 24px;
            line-height: 34px;
            padding: 8px 0;
            background-color: transparent;
        }
        div.chat,
        div.info {
            background-color: white;
        }
        div.chat {
            grid-area: chat;
            background-color: $white;
            position: relative;
            button {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                background-color: white;
                color: $green-300;
                position: absolute;
                bottom: 16px;
                cursor: pointer;
            }
            .switch-cam {
                left: 16px;
            }
            .open-attachment {
                right: 16px;
            }
            #local-camera {
                width: 150px;
                height: 150px;
                background-color: black;
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
        div.info {
            grid-area: info;
        }
        div.exit {
            grid-area: exit;
        }
    }
}
</style>